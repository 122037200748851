import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const subtitle = data.site.siteMetadata?.description
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle} subtitle={subtitle}>
        <SEO title="Shoddy Design" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} subtitle={subtitle}>
      <SEO title="Shoddy Design" />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          const image = post.frontmatter.featuredImage.publicURL.includes(
            ".gif"
          ) ? (
            <img
              src={post.frontmatter.featuredImage.publicURL}
              style={{
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                borderRadius: "0.5rem",
                maxWidth: "75%",
              }}
            />
          ) : (
            <Img
              fluid={post.frontmatter.featuredImage.childImageSharp.sizes}
              className="flex justify-center shadow-lg rounded-lg m-auto w-3/4"
            />
          )

          return (
            <li key={post.fields.slug} className="mb-16">
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2 style={{ marginBottom: "1rem" }}>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <div className="">{image}</div>
                </header>
                <section style={{ marginBottom: "2rem", marginTop: "2rem" }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                    style={{ marginBottom: "0.5rem" }}
                  />
                  <Link
                    to={post.fields.slug}
                    itemProp="url"
                    className="underline"
                  >
                    <span>How to fix it</span>
                  </Link>
                </section>
                <small>{post.frontmatter.date}</small>
              </article>
            </li>
          )
        })}
      </ol>
      {/* <Bio /> */}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      filter: { isFuture: { eq: false } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
            publicURL
            childImageSharp {
              sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
      }
    }
  }
`
